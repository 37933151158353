<template>
  <div class="serviceLeague">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
    >
      <div class="tab">
        <div
          :class="['item', { active: oIndex == index }]"
          v-for="(item, index) in tabList"
          :key="index"
          @click="toIndex(item, index)"
        >
          {{ item.dictValue }}
        </div>
      </div>
      <div class="serviceLeague-body">
        <div
          class="item"
          v-for="(item, index) in listData"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="item-l">
            <img :src="item.picture" alt="" />
          </div>
          <div class="item-r">
            <div class="name">{{ item.name }}</div>
            <div class="label">{{ item.serviceType }}</div>
            <div class="des">
              {{ item.description }}
            </div>
          </div>
        </div>
      </div>
    </v-list>
    <div class="wechatBox" v-if="isDialog" @click="closeDialog">
      <div class="img" @click.stop="showImg"><img :src="imgUrl" alt="" /></div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getLeagueListUrl, getLeagueInfoURL } from "./api.js";

import { gloabalCount } from "@/utils/common";
export default {
  name: "createWorkTalent",
  data() {
    return {
      tabList: [],
      isDialog: false,
      finishedText: "没有更多了",
      finished: false,
      imgUrl: "",
      oIndex: 0,
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      listData: [],
    };
  },
  mounted() {
    gloabalCount("", 38, 1);
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  methods: {
    toIndex(item, index) {
      this.oIndex = index;
      this.listData = [];
      this.getTalentList(item.dictValue);
    },
    async getServiceLeagueList() {
      let params = {
        tenantId: this.tenantId,
      };
      let res = await this.$axios.get(`${getLeagueInfoURL}`, {
        params: params,
      });
      if (res.code === 200) {
        this.tabList = res.data.businessServiceTypeList;
      }
    },
    showImg() {},
    closeDialog() {
      this.isDialog = false;
    },
    toWechat(item) {
      this.imgUrl = item.wechatQrcode;
      this.isDialog = true;
    },
    toAlink() {},
    returnData(data) {
      if (!data) {
        return "";
      }
      if (data >= 10000) {
        return Math.round(data / 10000) + "万";
      } else {
        return data;
      }
    },
    getTalentList(serviceType) {
      let params = {
        tenantId: this.tenantId,
        houseId: this.roomId || this.communityId || undefined,
        curPage: this.curPage,
        pageSize: this.pageSize,
        serviceType: serviceType,
      };
      this.$axios.get(`${getLeagueListUrl}`, { params: params }).then((res) => {
        console.log(res, 333);
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            if (item.activityPicture) {
              item.activityPicture = this.$handleImg(
                400,
                300,
                item.activityPicture
              );
            }
            this.listData.push(item);
          });
        } else {
        }
      });
    },
    async onLoad() {
      await this.getServiceLeagueList();
      this.getTalentList(this.tabList[0].dictValue);
    },
    toDetail(item) {
      //   let params = {
      //     activityId: item.activityId,
      //   };
      //   wx.miniProgram.navigateTo({
      //     url: `/xubPages/activityDetail/index?params=${JSON.stringify(params)}`,
      //   });
      this.$router.push({
        name: "serviceLeagueForm",
        query: { id: item.id },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.serviceLeague {
  background: #fafafa;
  min-height: 100vh;
  .wechatBox {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);
    .img {
      width: 400px;
      position: absolute;
      height: 400px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .tab {
    display: flex;
    justify-content: space-around;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
    background: #fff;
    .item {
      padding: 30px 0 30px;
      height: 40px;
      font-size: 32px;
      color: rgba(0, 0, 0, 0.5);
      line-height: 40px;
    }
    .active {
      font-weight: 600;
      color: #5e5aff;
      position: relative;
      &::after {
        content: "";
        width: 34px;
        height: 6px;
        background: #5e5aff;
        border-radius: 3px;
        position: absolute;
        bottom: 15px;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
  .serviceLeague-body {
    padding: 130px 20px 20px;

    .item {
      height: 220px;
      background: #ffffff;
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      border-radius: 16px;
      display: flex;
      margin-bottom: 34px;
      .item-l {
        width: 224px;
        height: 220px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 16px 0 0 16px;
        }
      }
      .item-r {
        padding: 20px 30px 0 24px;
        flex: 1;
        .name {
          font-size: 32px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          line-height: 44px;
        }
        .label {
          width: 142px;
          height: 38px;
          border-radius: 25px;
          border: 3px solid #615dff;
          font-size: 20px;
          font-weight: 600;
          color: #5e5aff;
          line-height: 38px;
          text-align: center;
          margin: 4px 0 14px;
          box-sizing: border-box;
        }
        .des {
          font-size: 24px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 34px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
</style>
