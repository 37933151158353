let origin = "https://future.zhuneng.cn";
//服务联盟列表
const getLeagueListUrl = `/gateway/hc-business/miniapi/league/getList`;

// 服务联盟详情
const getLeagueDetailURL = `/gateway/hc-business/miniapi/league/detail`;

//创业服务分类下拉框列表查询
const getLeagueInfoURL = `/gateway/hc-business/miniapi/league/getInitInfo`;

export { getLeagueListUrl, getLeagueDetailURL, getLeagueInfoURL };
